// Generated by Framer (7af8adc)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["y7cbm0ZDH", "nY_NAO5kn"];

const variantClassNames = {nY_NAO5kn: "framer-v-c6tnan", y7cbm0ZDH: "framer-v-v8dj82"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {Desktop: "y7cbm0ZDH", Mobile: "nY_NAO5kn"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "y7cbm0ZDH", image: GlBRZmGlF = {src: new URL("assets/Wbxgz0BfOqPpIHKVg2YxLgbUXU.svg", import.meta.url).href}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "y7cbm0ZDH", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-KLbYx", classNames)} style={{display: "contents"}}>
<Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 630, intrinsicWidth: 630, pixelHeight: 630, pixelWidth: 630, ...toResponsiveImage(GlBRZmGlF)}} className={cx("framer-v8dj82", className)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"y7cbm0ZDH"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({nY_NAO5kn: {"data-framer-name": "Mobile"}}, baseVariant, gestureVariant)}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-KLbYx [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-KLbYx * { box-sizing: border-box; }", ".framer-KLbYx .framer-15x7aae { display: block; }", ".framer-KLbYx .framer-v8dj82 { height: 452px; overflow: visible; position: relative; width: 452px; }", ".framer-KLbYx .framer-17g9oxn { flex: none; height: 452px; left: 0px; overflow: visible; position: absolute; top: 0px; width: 452px; }", ".framer-KLbYx.framer-v-c6tnan .framer-v8dj82 { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 265px); width: 265px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 452
 * @framerIntrinsicWidth 452
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"nY_NAO5kn":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"GlBRZmGlF":"image"}
 */
const FramerQxVQh0MwQ: React.ComponentType<Props> = withCSS(Component, css, "framer-KLbYx") as typeof Component;
export default FramerQxVQh0MwQ;

FramerQxVQh0MwQ.displayName = "ui/ux circle";

FramerQxVQh0MwQ.defaultProps = {height: 452, width: 452};

addPropertyControls(FramerQxVQh0MwQ, {variant: {options: ["y7cbm0ZDH", "nY_NAO5kn"], optionTitles: ["Desktop", "Mobile"], title: "Variant", type: ControlType.Enum}, GlBRZmGlF: {__defaultAssetReference: "data:framer/asset-reference,Wbxgz0BfOqPpIHKVg2YxLgbUXU.svg?originalFilename=Group+355906.svg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerQxVQh0MwQ, [])